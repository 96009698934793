<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenBtriService.current_stock') }} {{ $t('globalTrans.reports') }}</h4>
        </template>
        <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Event Type" vid="event_type" v-slot="{ errors }">
                <b-form-group
                    label-for="event_type"
                >
                <template v-slot:label>
                    {{ $t('teaGardenBtriService.clone_variety') }}
                    </template>
                    <b-form-select
                        plain
                        id="fiscal_year"
                        :options="masterCloneNameList"
                        v-model="search.clone_name_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
            </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
        <!-- search section end -->
      </card>

      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
                <!-- table section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenBtriService.current_stock') }} {{ $t('globalTrans.reports') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <a href="javascript:" class="btn-add" @click="pdfExport">
                     {{ $t('globalTrans.export_pdf') }}
                  </a>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                    <b-row>
                      <b-col>
                        <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="23">
                          <template v-slot:projectNameSlot>
                            {{ }}
                          </template>
                          {{ $t('teaGardenBtriService.current_stock') }} {{ $t('globalTrans.reports') }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <template>
                      <div class="text-black mb-4">
                        <b-row>
                          <b-col md="8">
                            {{ $t('teaGardenBtriService.clone_variety') }}: <strong>{{ search.clone_name_id ? ($i18n.locale === 'en' ? searchHeaderData.clone_name_en : searchHeaderData.clone_name_bn) : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                      </div>
                      <div>
                        <div class="table-responsive">
                          <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                            <b-thead>
                              <b-tr>
                                <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th style="width:15%" class="text-center">{{ $t('teaGardenBtriService.clone_variety') }}</b-th>
                                <b-th style="width:15%" class="text-center">{{ $t('teaGardenBtriService.current_stock') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(item, index) in datas" :key="index">
                                <b-td class="text-center">{{ $n(index + 1) }} </b-td>
                                <b-td class="text-center">{{ getCloneName(item.clone_name_id) }} </b-td>
                                <b-td class="text-center">{{ $n(item.stock_quantity) }} </b-td>
                              </b-tr>
                              <b-tr v-if="Object.keys(datas).length === 0">
                                <th colspan="7" class="text-center">{{ $t('globalTrans.no_data_found') }}</th>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                    </template>
                  </b-overlay>
                </template>
                <!-- table section end -->
          </body-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { currentStockReportApi } from '../../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import bazarMonitoringServiceMixin from '@/mixins/bazar-monitoring-service'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  Vue.use(excel)

  export default {
    mixins: [ModalBaseMasterList, bazarMonitoringServiceMixin],
    components: {
      ListReportHead
    },
    data () {
      return {
        titleLoad: false,
        eventList: [],
        valid: '',
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        search: {
          clone_name_id: 0
        },
        datas: [],
        searchHeaderData: {
          clone_name_en: '',
          clone_name_bn: ''
        },
        showData: false
      }
    },
    watch: {
      'search.event_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getEventList()
      }
    }
    },
    computed: {
      masterCloneNameList: function () {
        return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      getCloneName (id) {
        const data = this.masterCloneNameList.find(item => item.value === id)
        if (data) {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        }
      },
      async pdfExport () {
        const eventName = this.$i18n.locale === 'en' ? this.searchHeaderData.event_name_en : this.searchHeaderData.event_name_bn
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, event_name_text: eventName }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 21)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, currentStockReportApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        if (this.search.clone_name_id) {
          const obj = this.masterCloneNameList.find(data => data.value === this.search.clone_name_id)
          this.searchHeaderData.clone_name_en = obj !== undefined ? obj.text : ''
          this.searchHeaderData.clone_name_bn = obj !== undefined ? obj.text : ''
        }
        this.showData = true
        const params = Object.assign({}, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, currentStockReportApi, params)
        if (result.success) {
          this.datas = result.data
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
  </script>
